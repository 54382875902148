@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BigBlueTerminal";
  src: url("../assets/fonts/BigBlue_TerminalPlus.TTF") format("truetype");
  font-weight: normal;
}

@layer utilities {
  .stack {
    display: grid;
    grid-template: 1fr / 1fr;
  }

  .stack>* {
    grid-area: 1 / 1;
  }

  .anim-blink {
    animation: blink 2s infinite;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    49.99% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
}
